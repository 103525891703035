@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("deco");

.#{$component} {

  &__wave {
    height: 0;
    width: 100%;
    background-size: cover;
    padding: 0;
    padding-bottom: 90px;
    background-position: center;
    position: absolute;
    z-index: 5;

    &--wg {
      background-image: url('../../assets/img/WaterGreen-wave.svg');
    }
    &--wh {
      background-image: url('../../assets/img/ProvaWave.svg');
    }
    &--dg {
      background-image: url('../../assets/img/Darkgrey-wave.svg');
    }

  }

  &__sm-wave {
    height: 0;
    width: 100%;
    background-size: cover;
    padding: 0;
    padding-bottom: 31px;
    background-position: center;
    position: absolute;
    z-index: 5;
    &--dg {
      background-image: url('../../assets/img/Darkgrey-smwave.svg');
    }
  }

  &__reverse {
    &--hor {transform: scaleX(-1);}
    &--ver {transform: scaleY(-1);}
    &--all { transform: scale3d(-1,-1,1);}
  }

  &__bracket {
    &::before {
      content: "\003E";
      color: #6BBBAE;
      padding-right: 5px;
      position: absolute;
      top: 0;
      left: -20px;
      font-weight: 700;
    }
  }

  &__list {
  & ul {
      list-style: none;
      padding-top: 15px;
      text-align: left;
      padding-left: 0 !important;
    & li {
        border-top: 1px solid #6BBBAE;
        padding: 15px 0px 25px 30px;
        position: relative;
        font-weight: bold;
        &::before {
        content: "\003E";
        color: #6BBBAE;
        padding-right: 5px;
        position: absolute;
        top: 15px;
        left: 0px;
        font-weight: 700;
        }
        &:last-child {
          padding-bottom: 10px;
        }
      }
    }
  }

  &__param {
    box-shadow: 0px 5px 9px 0px #3d39351f !important;
    border-radius: 10px;
    position: relative;
    max-width: 350px;
    margin: auto;
    &::after {
      position: absolute;
      height: 100%;
      background-color: white;
      content: "";
      right: -11px;
      width: 30px;
      top: 0;
    }
    &::before {
      position: absolute;
      height: 100%;
      background-color: white;
      content: "";
      left: -11px;
      width: 30px;
      top: 0;
    }
  }

  &__background {
    &--contact {
      @include media-breakpoint-up(lg){
      background-image: url('../../media/BackContatti.png');
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      }
    }
  }

  &__slider-sx {
    width: 30px;
    border-radius: 30px;
    box-shadow: 5px 0 12px 0 #3D39351F;
    &::before {
      position: absolute;
      height: calc( 100% + 14px );
      background-color: white;
      content: "";
      left: -10px;
      width: 40px;
      top: -7px;
      // border-radius: 30px;
    }
  }

  &__slider-dx {
    width: 30px;
    border-radius: 30px;
    box-shadow: -5px 0 12px 0 #3D39351F;
    &::before {
      position: absolute;
      height: calc( 100% + 14px );
      background-color: white;
      content: "";
      right: -10px;
      width: 40px;
      top: -7px;
      // border-radius: 30px;
    }
  }

  &__client-blend {
        &::after {
          mix-blend-mode: screen;
          position: absolute;
          top: 0;
          left: 0;
          content: " ";
          width: 100%;
          height: 100%;
          display: block;
          opacity: 0;
          background-color: #6BBBAE;
          transition: opacity .5s ease;
        }
        &:hover {
          &::after {
            opacity: 1;
            transition: opacity .5s ease;
          }
    }
  }

  &__client-blend-2 {
    &::after {
      mix-blend-mode: lighten;
      position: absolute;
      top: 0;
      left: 0;
      content: " ";
      width: 100%;
      height: 100%;
      display: block;
      opacity: 0;
      background-color: #6BBBAE;
      transition: opacity .5s ease;
    }
    &:hover {
      &::after {
        opacity: 1;
        transition: opacity .5s ease;
      }
}
}

}
