@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("nav3");

.#{$component} {
  width: 100%;
  @include media-breakpoint-down(lg) {
    overflow-x: auto;
    overflow-y: hidden;
    // box-shadow: inset -10px 0px 25px -25px #0000004D,
  }
   /* Hide scrollbar for IE, Edge and Firefox */
   -ms-overflow-style: none;  /* IE and Edge */
   scrollbar-width: none;  /* Firefox */
    &::-webkit-scrollbar {
      display: none;
      }

  ul {
    list-style: none;
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    @include media-breakpoint-down(lg) {
      align-items: center;
    }
  }
  li {
    // margin: -1px;
    margin: 0;
    padding-bottom: 20px;
    font-size: 12px;
    letter-spacing: 1.92px;
    font-weight: 800;
    line-height: 16px;
    @include media-breakpoint-down(lg) {
      margin-right: 30px;
    }
    a {
      color: #3D3935;
    }
    a:hover {
      color: tokenColor(set1, greenwater);
    }
  }
  li.is-active {
    border-bottom: 1px solid  tokenColor(set1, greenwater);
    a {
      color: tokenColor(set1, greenwater);
    }
  }

}
