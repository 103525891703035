@charset "UTF-8";

@import "../abstracts/variables";
@import "../abstracts/functions";
@import "../abstracts/mixins";
@import "../abstracts/placeholders";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$zaux-helper-name: "size";


// //min-width


.mih-lg-f100vh {
  min-height: calc( 100vh - ( 150px - 90px ) );
  @include media-breakpoint-down(lg) {
    min-height: calc( 100vh - (150px - 90px ));
  }
}

.mih-lg-800 {
  @include media-breakpoint-up(lg) {
    min-height: 800px;
  }
}

// //// Box Custom ///
.mih-sleepbox {
  min-height: 748px;
  @include media-breakpoint-up(lg) {
    min-height: 800px;
  }
}

.mih-hero1 {
  min-height: 620px;
  @include media-breakpoint-up(lg) {
    min-height: 630px;
  }
}

.mih-int-wavebox {
  min-height: 425px;
  @include media-breakpoint-up(lg) {
    min-height: 488px;
  }
}


// .mih-seminarcard {
//   min-height: 291px;
//   @include media-breakpoint-up(lg) {
//     min-height: 210px;
//   }
// }

//min height
// .mih-200 {
//   min-height: 200px;
// }
// .mih-215 {
//   min-height: 215px;
// }
// .mih-291 {
//   min-height: 291px;
// }
.mih-f396 {
  min-height: 396px;
}
.mih-f488 {
  min-height: 488px;
}
// .mih-560 {
//   min-height: 560px;
// }
// .mih-800 {
//   min-height: 800px;
// }


//max width


.maw-f250 {
  max-width: 250px;
}

// .maw-f350 {
//   max-width: 350px;
// }

// .maw-f550 {
//   max-width: 550px;
// }

.maw-f630 {
  max-width: 630px;
}

.maw-f850 {
  max-width: 850px;
}

.maw-f960 {
  max-width: 960px;
}

.maw-f1290 {
  max-width: 1290px;
}


// //Custom Time Box

.zaux-#{$zaux-helper-name}-profile {
  min-height: 444px;
  max-width: 300px;
  @include media-breakpoint-down(lg) {
    width: 280px;
    min-height: 424px;
  }
}

.zaux-#{$zaux-helper-name}-wave-contact {

    @include media-breakpoint-up(md) {
      transform: translateX(100px) scale(1.2);
    }
}
.zaux-#{$zaux-helper-name}-contact {

  @include media-breakpoint-down(lg) {
    height: 320px;
    width: auto;
  }
  @include media-breakpoint-down(md) {
    height: 230px;
    width: auto;
  }
}

.zaux-#{$zaux-helper-name}-contact-title {
  max-width: 630px;

  @media screen and (min-height: 992px) and (max-height : 1280px) {
    max-width: 530px;
  }
}

.zaux-#{$zaux-helper-name}-news {
  min-height: 368px;
  @include media-breakpoint-down(lg) {
  min-height: 269px;
  }
}

.zaux-#{$zaux-helper-name}-wavebox {
  height: 90px;
  @include media-breakpoint-down(lg) {
  height: 60px;
  }
}
.zaux-#{$zaux-helper-name}-wavebox2 {
  height: 30px;
  @include media-breakpoint-down(lg) {
    height: 20px;
  }
}
.zaux-#{$zaux-helper-name}-custom1 {
  @include media-breakpoint-down(lg) {
    height: 20px;
    width: auto;
  }
}
.zaux-#{$zaux-helper-name}-custom2 {
  @include media-breakpoint-down(lg) {
    height: 30px;
    width: auto;
  }
}



// .zaux-#{$zaux-helper-name}-timebox {
//   max-width: 183px;
//   max-height: 183px;
//   @include media-breakpoint-down(lg) {
//     max-width: 105px;
//     max-height: 105px;
//   }
// }


