@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("boxcontent");

.#{$component} {

  &__content{
    &:hover {
      cursor: pointer;
      h6 {
        text-decoration: underline;
      }
    }
    &--webcast{
      span {
        font-size: 14px;
        @include media-breakpoint-down(lg) {
        font-size: 12px;
      }
    }
    p {
      margin-bottom: 0 !important;
      line-height: 1 !important;
    }
  }
}
}
