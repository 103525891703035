@charset "UTF-8";

@import "../abstracts/variables";
@import "../abstracts/functions";
@import "../abstracts/mixins";
@import "../abstracts/placeholders";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$zaux-helper-name: "spacing";

.p-box {
  padding: $column-padding-box;
  @include media-breakpoint-up(lg) {
  padding: $column-padding-lg-box;
  }
}

.pe-box {
  padding-right: 20px;
  @include media-breakpoint-up(lg) {
  padding-right: 93px;
  }
}

.ps-box {
  padding-left: 17px;
  @include media-breakpoint-up(lg) {
  padding-left: 315px;
  }
}
