@charset "UTF-8";

// Common weight name mapping
// The numerical values 100 to 900 roughly correspond to the following
// common weight names (see the OpenType specification):
//
// @see https://developer.mozilla.org/en-US/docs/Web/CSS/font-weight
// @see https://docs.microsoft.com/en-us/typography/opentype/spec/os2#usweightclass

// Value	Common weight name
// ------------------------------------------------------
// 100	Thin (Hairline)
// 200	Extra Light (Ultra Light)
// 300	Light
// 400	Normal (Regular)
// 500	Medium
// 600	Semi Bold (Demi Bold)
// 700	Bold
// 800	Extra Bold (Ultra Bold)
// 900	Black (Heavy)
// 950	Extra Black (Ultra Black)
// ------------------------------------------------------

[class^="icozaux1-"],
[class*=" icozaux1-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

// [class^="-share"],
// [class*="-share"] {
//   display: inline-block;
//   // width: 40px;
//   // height: 40px;
//   stroke-width: 0;
//   stroke: currentColor;
//   fill: currentColor;
//   cursor: pointer;
//   @include media-breakpoint-down(lg) {
//     width: 30px;
//     height: 30px;
//   }
// }

// .icozaux1-fb, .icozaux1-tw, .icozaux1-yt, .icozaux1-ln {
//   font-size: 16px;
// }

.icozaux1-fb:hover, .icozaux1-tw:hover, .icozaux1-yt:hover, .icozaux1-ln:hover {
  fill: tokenColor(set1, greenwater);
}

// @font-face {
//   font-family: 'Work Sans';
//   src: url('../fonts/WorkSans/WorkSans-Bold.eot');
//   src: url('../fonts/WorkSans/WorkSans-Bold.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/WorkSans/WorkSans-Bold.woff2') format('woff2'),
//       url('../fonts/WorkSans/WorkSans-Bold.woff') format('woff');
//   font-weight: bold;
//   font-style: normal;
//   font-display: swap;
// }

// @font-face {
//   font-family: 'Work Sans';
//   src: url('../fonts/WorkSans/WorkSans-Regular.eot');
//   src: url('../fonts/WorkSans/WorkSans-Regular.eot?#iefix') format('embedded-opentype'),
//       url('../fonts/WorkSans/WorkSans-Regular.woff2') format('woff2'),
//       url('../fonts/WorkSans/WorkSans-Regular.woff') format('woff');
//   font-weight: normal;
//   font-style: normal;
//   font-display: swap;
// }

@font-face {
  font-family: 'Flexo';
  src: url('../fonts/Flexo/Flexo-It.eot');
  src: url('../fonts/Flexo/Flexo-It.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Flexo/Flexo-It.woff2') format('woff2'),
      url('../fonts/Flexo/Flexo-It.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Flexo';
  src: url('../fonts/Flexo/Flexo-Bold.eot');
  src: url('../fonts/Flexo/Flexo-Bold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Flexo/Flexo-Bold.woff2') format('woff2'),
      url('../fonts/Flexo/Flexo-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Flexo';
  src: url('../fonts/Flexo/Flexo-Demi.eot');
  src: url('../fonts/Flexo/Flexo-Demi.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Flexo/Flexo-Demi.woff2') format('woff2'),
      url('../fonts/Flexo/Flexo-Demi.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Flexo';
  src: url('../fonts/Flexo/Flexo-Light.eot');
  src: url('../fonts/Flexo/Flexo-Light.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Flexo/Flexo-Light.woff2') format('woff2'),
      url('../fonts/Flexo/Flexo-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Flexo';
  src: url('../fonts/Flexo/Flexo-Regular.eot');
  src: url('../fonts/Flexo/Flexo-Regular.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Flexo/Flexo-Regular.woff2') format('woff2'),
      url('../fonts/Flexo/Flexo-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Flexo';
  src: url('../fonts/Flexo/Flexo-Medium.eot');
  src: url('../fonts/Flexo/Flexo-Medium.eot?#iefix') format('embedded-opentype'),
      url('../fonts/Flexo/Flexo-Medium.woff2') format('woff2'),
      url('../fonts/Flexo/Flexo-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

