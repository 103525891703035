@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("header");

$zaux-header-h: 80px !default;
$zaux-header-lg-h: 160px !default;

.#{$component} {


  &__top-bar {
    height: 60px;
    position: fixed;
    width: 100%;
    z-index: 15;
    top: 0;
    left: 0;
    @include media-breakpoint-down(lg) {
      height: 60px;
    }
    &--svg {
      filter: drop-shadow(0 3px 30px #3D39351F);
      @include media-breakpoint-down(lg) {
      filter: drop-shadow(0 13px 13px #3D39350D);
      }
    }
  }

  &__logo {
    img {
      @include media-breakpoint-down(lg) {
        width: 155px;
        height: 40px;
      }
    }
  }
  &__bottom-bar {
    height: 60px;
    margin-top: 60px;
    position: relative;
    z-index: 14;
    @include media-breakpoint-down(lg) {
      height: 60px;
      margin-top: 60px;
    }
    &--info {
      height: 100%;
      @include media-breakpoint-up(lg) {
      height: 90px;
      }
    }
  }

  &__btn-lang {
    & .form-select {
      height: 24px;
      font-size: 16px;
      padding: 1px 10px 1px 1px;
      border: 0;
      color: #3D3935;
    }
  }

  &__btn-login {
    a:hover {
      color: tokenColor(set1, greenwater) !important;
    }
  }

  &__btn-menu {
    a:hover {
      color: tokenColor(set1, greenwater) !important;
    }
    &.is-active {
      a {
        color: tokenColor(set1, greenwater) !important;
      }
      & .hamburger--close {
        display: inline !important;
      }
      & .hamburger--menu {
        display: none;
      }
    }
  }

  &__logo {
   position: absolute;
  }


}
