@import "../../assets/scss/components/base";

$component: componentClass("pagination");

.#{$component} {

  & .wp-pagenavi {
    border-top: 1px solid #D9DAE4;
  }

& a {
  &:hover {
    color: tokenColor(set1, greenwater);
  }
}
  & a,
  & span.extend {
    color: tokenColor(set1,darkgrey);
  }

  & .current {
    color: tokenColor(set1, greenwater);
    border-top: 1px solid #6BBBAE !important;
  }

}
