@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card1");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {


  &--theme--light {

  & .#{$component}__content {
    background-color: #3F3F3FBF;

    &:hover {
      background-color: #3F3F3FF2;
    }
  }

  & .#{$component}__contentbox {
    background-color: #3F3F3FBF;

    &:hover {
      background-color: #3F3F3FF2;
    }
  }

}

}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

