@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("breadcrumbs");

.#{$component} {

  > span, a {
  letter-spacing: 1.92px;
  color: tokenColor(set1,grey2);
  font-size: 12px;
  }
  span.breadcrumb_last {
    color: tokenColor(set1, darkgrey);
  }

  &.theme-white {
    > span, a {
      color: white !important;
    }
  }
}
