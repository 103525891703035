@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("pagination");

.#{$component} {
  margin: auto;
  text-align: center;

  & .wp-pagenavi {
    align-items: center;
    justify-content: center;
    display: flex;
    & .page, & span, & a {
      border: none;
    }
    & .pages {
      border: none;
      padding-top: 20px;
    }
  }
  & a,
  .current,
  & span.extend {
    padding-top: 20px;
    font-weight: 700;

    display: inline-block;
    text-decoration: none;
    width: 30px;
    margin-top: -1px;

    &.previouspostslink {
      margin-right: 25px;
      @include media-breakpoint-up(lg) {
        margin-right: 50px;
      }
      background-image: url('../../assets/img/pg-arrow-sx.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      margin-top: 10px;
      &:hover {
        background-image: url('../../assets/img/pg-arrow-sx-hov.svg');
      }
    }

    &.nextpostslink {
      margin-left: 15px;
      margin-right: 0;
      @include media-breakpoint-up(lg) {
        margin-left: 35px;
      }
      background-image: url('../../assets/img/pg-arrow-dx.svg');
      background-repeat: no-repeat;
      background-position: bottom;
      margin-top: 10px;
      &:hover {
        background-image: url('../../assets/img/pg-arrow-dx-hov.svg');
      }
    }
  }
  & .current {
    padding-top: 19px;
  }

}
