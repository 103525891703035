@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("footer");


.#{$component} {

  &__container {
    &--img {
    padding-bottom: 40px;
      picture {
        @include media-breakpoint-down(lg) {
          text-align: center;
        }
      }
    }
    &--list {
      @include media-breakpoint-down(lg) {
        text-align: center;
      }
    }
    &--box1--info {
      & p {
        margin-bottom: 0 !important;
      }
    }
    &--box2--social {
      & a {
        color: white !important;
        &:hover {
          color: tokenColor(set1, greenwater);
        }
      }
    }
    &--box2--btn-lang {
      display: flex;
      @include media-breakpoint-up(lg) {
      position: absolute;
      bottom: 0;
      }
    }

    &--box2--nav1 {
     & nav div a {
        color: white !important;
        &:hover {
          color: tokenColor(set1, greenwater) !important;
          text-decoration: none;
        }

      }
      & ul li {
        @include media-breakpoint-down(md) {
          white-space: nowrap;
        }
        &::before {
          content: "\003E";
          color: #6BBBAE;
          padding-right: 5px;
        }
      }
    }
  }

  & ul {
  list-style-type: none;
  padding-left: 0;
   & li {
    margin-bottom: 20px;
    & a {
    text-decoration: none;
    color: white !important;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      color: tokenColor(set1,greenwater) !important;
    }
   }

  }
  & li:last-child {
    margin-bottom: 0 !important;
  }
  }
  // & .c-icon {
  //     width: 30px;
  // }

}
.#{$component}__last {

  & a {
    position: absolute;
    top: 0;
    right: 20px;
    text-decoration: none;
  }
}
