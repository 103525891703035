@import "../../assets/scss/components/base";

$component: componentClass("modale");

.#{$component} {

    &--light {
      background-color: #D6D6D6;
      pointer-events: auto;
    }

}
