@import "../../assets/scss/components/base";

$component: componentClass("form");

$form-select-arrow : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#fff' class='bi bi-caret-down-fill' viewBox='0 0 16 16'> <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
$form-select-arrow-dark : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#3D3935' class='bi bi-caret-down-fill' viewBox='0 0 16 16'> <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
$form-radio: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='#269A91'/%3e%3c/svg%3e");

$form-select-arrow-hover : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#6BBBAE' class='bi bi-caret-down-fill' viewBox='0 0 16 16'> <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");

.#{$component} {

  &--light1 {
    & select {
      color: #fff;
      background-image: escape-svg($form-select-arrow);
      background-position: right 4px top 7px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 10px;
      &:hover {
        color: tokenColor(set1, greenwater);
        background-image: escape-svg($form-select-arrow-hover);
      }
      & option {
        color: tokenColor(set1, darkgrey);
      }
    }
  }

  &--dark1 {
    & input {
      background-color: #D9DAE4;
      &::placeholder {
        color: #7C8795;
      }
    }
    & select {
      color: #3D3935;
      background-image: escape-svg($form-select-arrow-dark);
      background-position: right 4px top 7px;
      background-color: transparent;
      background-repeat: no-repeat;
      background-size: 10px;
      // background-position: calc(100% - 15px) center;
      &:hover {
        color: tokenColor(set1, greenwater);
        background-image: escape-svg($form-select-arrow-hover);
      }
      & option {
        color: tokenColor(set1, darkgrey);
      }
    }

  }

  &--dark {
    & .#{$component}__newsletter {
      &--box {
        background-color: #D9DAE480;
        & input {
          background-color: transparent;
        }
        & input[type="submit"] {
          background-color: #6BBBAE;
          &:hover {
            background-color: #269A91;
          }
        }
      }
      & label {
        color: tokenColor( set1, darkgrey);
      }
    }
    & input[type="checkbox"] {
      border: 2px solid #3D3935;
      border-radius: 6px;
    }

  }

  &--light {

    & .#{$component}__newsletter {
      label {
        color: white;
      }

      & input[type="checkbox"] {
        background-color: transparent;
        border: 2px solid white;
        border-radius: 6px;
      }
    }

    & .#{$component}__newsletter--box {
      background-color: #fff;
    }



    & a {
      color: #6BBBAE !important;
    }

}

& input[type="radio"][checked="checked"] {
    background-image: escape-svg($form-radio);
}
.form-check-input:checked[type="checkbox"] {
  background-color: #6BBBAE;
  border-color: #6BBBAE;
}

}
