@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("accordion");


.#{$component} {
  width: 100%;
  position: relative;
  @include media-breakpoint-up(lg) {
    width: 825px;
    margin: auto;
}

  & {
    & .#{$component}__head::after {
      content: '+';
      position: absolute;
    right: 25px;
    top:24px;
    font-size: 30px;
    font-weight: 600;
    @include media-breakpoint-down(lg) {
    top:8px;
    }
    }
  }
    & {
      & .#{$component}__inner {
        cursor: pointer;
        &:hover {
          background-color: #F9F9F9 !important;
        }
      }
    }

  &__inner {

    & .#{$component}__head {
          height: 60px;
        @include media-breakpoint-up(lg) {
            height: 90px;
      }
    }
    & ul {
      list-style: none;
    }
  }
  &__inner.active  {

    & .#{$component}__head::after {
    content: '-';
  }

}

  &__inner.active  .#{$component}__head {
   border-bottom: 5px solid #F1F1F1;
   &::after {
    top:20px;
    @include media-breakpoint-down(lg) {
      top:4px;
  }
   }

  }
  &__inner.active  .#{$component}__show {
    display: block !important;
  }

}
