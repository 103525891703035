@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("slider");

.#{$component} {

  &--lay1 {
    & .c-slider__wrapper {
      max-width: 1350px;
      margin: auto;
    }
    & .swiper-wrapper {
      @include media-breakpoint-down(md) {
        padding-left: 20px;
      }
    }
    & .swiper-slide {
      height: auto;
    }
  }

  &--lay2 {
    & .c-slider__wrapper {
      max-width: 1320px;
      margin: auto;
    }
    & .swiper-wrapper {
      @include media-breakpoint-down(lg) {
        padding-left: 20px;
      }
    }
    & .swiper-slide {
      height: auto;
    }
  }

  .c-slider__pagination {
    max-width: 1320px;
    transform: translateY(-75px);
    margin: auto;
    position: relative;
    z-index: 1;
    @include media-breakpoint-down(lg) {
      transform: translateY(-40px);
    }
  }
  .swiper-pagination-horizontal {
    position: relative;
    // margin-left: -15px;
    // margin-right: -15px;
    display: flex;
      // justify-content: space-between;
    // bottom: 60px;
    // left: calc( 100% - ( 1290px  + ( 1290px / 2 ) ) ) ;
  &.swiper-pagination-bullets .swiper-pagination-bullet {
      margin: 0 var(--swiper-pagination-bullet-horizontal-gap, 15px);

  }

  }
  .swiper-pagination-bullet {
    border-radius: 0;
    // width: calc( 25% - 30px );
    width: 100%;
    height: 2px;
    background-color: tokenColor(set1, greenwater);
    &.swiper-pagination-bullet-active {
      background-color: white;
    }

  }

  .swiper-navigation {
    & .swiper-button-next, & .news-swiper-button-next, & .timeline-swiper-button-next {
      right: 0;
      &::after {
        content: '';
      }
      & i:hover {
        color: tokenColor(set1, greenwater) !important;
      }
    }

    & .swiper-button-prev, & .news-swiper-button-prev, & .timeline-swiper-button-prev {

      left: 0;
      &::after {
        content: '';
      }
      & i:hover {
        color: tokenColor(set1, greenwater) !important;
      }
    }


    & .news-swiper-button-next, & .news-swiper-button-prev,
    & .timeline-swiper-button-next, & .timeline-swiper-button-prev {
    font-size: 16px;
    position: absolute;
    top: 50%;
    width: calc(var(--swiper-navigation-size) / 44 * 27);
    height: var(--swiper-navigation-size);
    margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
    z-index: 10;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--swiper-navigation-color, var(--swiper-theme-color));
    }
  }

  .timeline-swiper-button-next.swiper-button-disabled,
  .news-swiper-button-next.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }
  .timeline-swiper-button-prev.swiper-button-disabled,
  .news-swiper-button-prev.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
  }

}
