@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("cta");

.#{$component} {
  &1 {
    transition: background-color .5s ease !important;
    &--gw {
      background-color: tokenColor(set1, greenwater);
      color: tokenColor(default, white) !important;
      &:hover {
        background-color: tokenColor(set1, greenwater4);
        transition: background-color .5s ease;
        &::before {
          color: tokenColor(default, white) !important;
          transition: color .5s ease;
        }
      }
      &::before {
        content: "\003E";
        padding-right: 10px;
        color: tokenColor(set1, greenwater4);
        // @include media-breakpoint-up(lg) {
        //   padding-right: 10px;
        // }
      }
    }

  }

  &2 {
    transition: color .5s ease;
    &--light {
      color: #FFFFFF;
      &:hover {
        color: tokenColor(set1, greenwater) !important;
      }
  }
  &--dark {
    color: #3D3935 !important;
    &:hover {
      color: tokenColor(set1, greenwater) !important;
      transition: color .5s ease;
    }
}

  }

  &3 {
    transition: background-color .5s ease;
    box-shadow: 0 3px 12px 0 #7C879440;
    color: #3D3935 !important;
    &--light {
      background-color: white;
    }
    &--gw {
      background-color: #DBEDED;
    }
    &:hover {
      color: tokenColor(set1, greenwater) !important;
      transition: color .5s ease;
    }
  }

  &--selected {
    border-color: tokenColor(set1, red1);
  }
}
