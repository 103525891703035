@charset "UTF-8";

$zaux-helper-name: "color";

////////////////////////////////////////////////////
// HELPER MIXINS
////////////////////////////////////////////////////
@mixin zaux-helper-color($name, $color) {
  .zaux-color-#{$name} {
    color: $color !important;
  }
}

@mixin zaux-helper-bgcolor($name, $color) {
  .zaux-bg-#{$name} {
    background-color: $color !important;
  }
}

////////////////////////////////////////////////////
// HELPER CLASSES
////////////////////////////////////////////////////

@each $setName, $setObj in tokenColors() {
  @each $colorName, $colorVal in $setObj {
    @include zaux-helper-color("#{$setName}-#{$colorName}", $colorVal);
  }
}

@each $setName, $setObj in tokenColors() {
  @each $colorName, $colorVal in $setObj {
    @include zaux-helper-bgcolor("#{$setName}-#{$colorName}", $colorVal);
  }
}

////////////////////////////////////////////////////
// HELPER CSS VARIABLES
////////////////////////////////////////////////////

// :root {
//   --#{tokenDef('css-var-prefix')}-test: #f00;
// }

.border-left-gradient {
  border-left-width: 2px;
  border-left-style: solid;
  border-image: linear-gradient(to bottom ,#b827fc 0%, #fd1892 25%, #fec837 50%,  #b8fd33 75%, #2c90fc 100%) 1;
}

.border-all-gradient {
  border-width: 2px;
  border-style: solid;
  border-image: linear-gradient(to right ,#b827fc 0%, #fd1892 25%, #fec837 50%,  #b8fd33 75%, #2c90fc 100%) 1;
}

.darkgrey-opacity {
  background-color: #3F3F3F99;
}

.timeline-gradient {
  background: linear-gradient(to bottom, #D9DAE400 0%, #7C87941F 50%, #D9DAE400 100%);
}

.darkgreen-bg {
  &::after {
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    z-index: 4;
    background: linear-gradient(to bottom, #70BAAE, #3D3935);
  }
}

.darkgreen-bg-op {
  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    z-index: 4;
    background: linear-gradient(to bottom, #70BAAE1F, #3D3935);
  }
}

.darkgreen-bg-op-sticky {
  &::after {
    mix-blend-mode: multiply;
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 100%;
    height: 100%;
    display: block;
    background: linear-gradient(to bottom, #70BAAE1F, #3D3935);
    border-radius: 20px;
  }
}

.zaux-bg-watg-gradient {
  background: linear-gradient(to bottom, #339991, #3d3935);
  @include media-breakpoint-down(lg) {
    background: linear-gradient(to bottom, #339991 50%, #3d3935);
  }
}
