@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("header");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

.#{$component} {

  &__top-bar {
    background-color: white;
  }
  &__bottom-bar {
    background-color: tokenColor(set1, darkgrey);
  }


}
