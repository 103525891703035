@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("form");

$form-select-arrow-dark1 : url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='#3D3935' class='bi bi-caret-down-fill' viewBox='0 0 16 16'> <path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>");
.#{$component} {

&__newsletter {

  &--box {
    width: 100%;
    &.form-group {
      // width: 65%;
      // @include media-breakpoint-down(md) {
      //   width: 50%;
      // }
    & input {
      height: 100%;
      border: 0;
      padding-left: 20px;
      &:focus {
        box-shadow: none;
      }
    }
  }
  }

  & .checkbox {

    &  .form-check-label {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.12em;
        font-weight: 400;
        width: 100%;
        margin-top: -20px;
        padding-left: 6px;
        float: left;
         a {
          white-space: nowrap;
            color: tokenColor(set1,greenwater);
            font-weight: bold;
            cursor: pointer;
          }
        }
      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        float: left;
        &:focus {
          box-shadow: none !important;
        }
        &:checked {
        background-color: #6BBBAE;
        box-shadow: none !important;

        }
      }
      & .wpcf7-list-item {
        margin: 0;
      }
    }
  & input[type="submit"] {
    font-weight: 700;
    border-radius: 30px;
    letter-spacing: 0.12em;
    line-height: rem(12px);
    font-size: rem(12px);
    padding: 12px 18px 12px 18px;
    @include media-breakpoint-up(lg) {
      padding: 14px 20px;
    }
    border: none;
    transition: background-color .5s ease, color .5s ease;
    background-color: #6BBBAE;
    color: #FFFFFF !important;
    &:hover {
      background-color: #269A91; }
  }
}


&__contact {
  @include media-breakpoint-up(lg) {
    background-color: white;
    box-shadow: 0px 0px 10px 5px #3D39351F;
    width: 100%;
    padding: 30px;
    border-radius: 30px;
    }
    width: 100%;
  & .form-group {
    padding-bottom: 25px;
    & label {
      padding-left: 30px;
      padding-bottom: 10px;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 0.12em;
    }
    & input:not([type="checkbox"]) {
    height: 60px;
    border-radius: 30px;
    background-color: #D9DAE480;
    padding: 30px 30px;
    border: none;
    &::placeholder {
      font-size: rem(16px);
      color:#7C8795;
      opacity: 50%;
    }
    &:focus {
      // border: 3px solid #D32E12;
      box-shadow: none !important;
    }
    }

  & textarea {
    height: 160px;
    border-radius: 30px;
    padding: 30px 30px;
    background-color: #D9DAE480;
    border: none;
    &::placeholder {
      font-size: rem(16px);
      color: #7C8795;
      opacity: 50%;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  & .checkbox {
        margin-bottom: 50px;
    &  .form-check-label {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.12em;
        font-weight: 400;
        width: 100%;
        margin-top: -20px;
        padding-left: 6px;
        float: left;
         a {
          white-space: nowrap;
            color: tokenColor(set1,greenwater);
            font-weight: bold;
            cursor: pointer;
          }
        }
      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        float: left;
        &:focus {
          box-shadow: none !important;
        }
        &:checked {
        background-color: #6BBBAE;
        box-shadow: none !important;

        }
      }
      & .wpcf7-list-item {
        margin: 0;
      }
    }
  }
  & input[type="submit"] {
    font-weight: 700;
    border-radius: 30px;
    letter-spacing: 0.12em;
    line-height: 26px;
    font-size: 16px;
    padding: 17px 25px 17px 30px;
    border: none;
    transition: background-color .5s ease, color .5s ease;
    background-color: #6BBBAE;
    color: #FFFFFF !important;
    &:hover {
      background-color: #269A91; }
  }
}


&__thesis {
  & .form-group {
    padding-bottom: 25px;
    & label {
      padding-left: 30px;
      padding-bottom: 10px;
      font-size: 12px;
      font-weight: 700;
      letter-spacing: 1.92px;
    }
    & input:not([type="checkbox"]) {
    height: 60px;
    border-radius: 30px;
    background-color: #D9DAE480;
    border: none;
    padding: 30px 20px 30px 30px;
    &::placeholder {
      font-size: rem(16px);
      color:#7C8795;
      opacity: 50%;
    }
    &:focus {
      // border: 3px solid #D32E12;
      box-shadow: none !important;
    }
    }

    & select {
      height: 60px;
      border-radius: 30px;
      background-color: #D9DAE480;
      border: none;
      padding-left: 30px;
      display: block;
      width: 100%;
      & option {
        color: #3D3935;
      }
      background-image: escape-svg($form-select-arrow-dark1);
      background-position: right 30px center;
      }

  & textarea {
    height: 160px;
    border-radius: 30px;
    padding: 30px;
    background-color: #D9DAE480;
    border: none;
    &::placeholder {
      font-size: rem(16px);
      color: #3F3F3F;
      opacity: 50%;
    }
    &:focus {
      box-shadow: none !important;
    }
  }
  & .form-check {
    margin-left: 30px;
    display: inline-block;
    & label {
      padding-left: 5px;
      padding-bottom: 0;
    }
    & input[type="radio"] {
      -webkit-appearance: none;
      appearance: none;
      border: 2px solid #3D3935;
      height: 20px;
      width: 20px;
      padding: 0;
      border-radius: 100%;
      &:before {
        content: '';
        display: block;
        width: 75%;
        height: 75%;
        margin: 12% auto;
        border-radius: 50%;
    }
    &:checked:before {
      background: #6BBBAE;

  }
    }
  }
  & p {
    margin-left: 30px;
    margin-bottom: 18px;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.12em;
  }
  & .checkbox {
    margin-left: 0 !important;
    &  .form-check-label {
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.12em;
        font-weight: 400;
        width: 100%;
        margin-top: -20px;
        padding-left: 8px;
        float: left;
         a {
          white-space: nowrap;
            color: tokenColor(set1,greenwater);
            font-weight: bold;
            cursor: pointer;
          }
        }
      input[type="checkbox"] {
        width: 20px;
        height: 20px;
        float: left;
        &:focus {
          box-shadow: none !important;
        }
        &:checked {
        background-color: #6BBBAE;
        box-shadow: none !important;

        }
      }
      & .wpcf7-list-item {
        margin: 0;
      }
    }

  & .form-check.radio-btn {
    padding-left: 0;
   & .wpcf7-list-item.first {
    margin: 0;
   }
   & .wpcf7-list-item.last {
    margin: 0 0 0 30px;

   }
   & .wpcf7-list-item-label {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      padding-left: 8px;
      padding-bottom: 0;
      vertical-align: top;
   }
  }

  }
  & input[type="submit"] {
    font-weight: 700;
    border-radius: 30px;
    letter-spacing: 0.12em;
    line-height: 26px;
    font-size: 16px;
    padding: 17px 25px 17px 30px;
    border: none;
    transition: background-color .5s ease, color .5s ease;
    background-color: #6BBBAE;
    color: #FFFFFF !important;
    &:hover {
      background-color: #269A91; }
  }

}

&__field-select {
  width: 40px;
  & select {
    padding: 0;
    border: none;
  }
}


}
