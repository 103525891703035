@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("nav");

.#{$component} {

  ul {
    padding-left: 10px;
    padding-top: 30px;
    li {
      font-weight: 800;
      font-size: 16px;
      letter-spacing: 1.92px;
      line-height: 20px;
      padding-left: 10px;

      &:not(:last-child) {
        margin-bottom: 20px;
      }
      &::marker {
        content: "\003E";
        color: #6BBBAE;
        font-weight: 700;
      }

      & a {
        color: white;
        :hover {
          color: #6BBBAE !important;
        }
      }
    }
  }
  &__submenu--item {
    > a {
      &:hover {
        color: tokenColor(set1,greenwater);
      }
    }
  }
}
