$zaux-token-colors:(
  default: (
    white: #FFFFFF,
    black: #333333,
    red: #d9534f,
    green: #5cb85c,
    blue: #337ab7,
    magenta: #FF00FF,
    orange: #f0ad4e
  ),
  app: (
    theme: #475461
  ),
  set1: (
    darkgrey: #3D3935,
    greenwater: #6BBBAE,
    greenwater1: #DBEDED,
    greenwater2: #B9DDDD,
    greenwater3: #A1D6D4,
    greenwater4: #269A91,
    grey: #D9DAE4,
    grey1: #D0CED5,
    grey2: #7C8795
  )
);
