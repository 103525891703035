@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("cta");

a[class*="#{$component}"] {
  display: inline-block;
  text-decoration: none;
}

[class*="#{$component}"][class*="--size"] {
  text-align: center;
}

.#{$component} {

  &1 {
    white-space: nowrap;
    font-weight: $zaux-typo-fw-bold;
    border-radius: 30px;
    letter-spacing: 0.12em;

    &--sm {
      line-height: rem(12px);
      font-size: rem(12px);
      padding: 12px 18px 12px 18px;
      @include media-breakpoint-up(lg) {
        // padding: map-get($spacers, "4");
        padding: 14px 20px;
      }
    }
    &--md {
      line-height: 26px;
      font-size: 16px;
      // padding: 15px 25px 15px 30px;
      padding: 17px 25px 17px 30px;
      // @include media-breakpoint-up(lg) {
      //   line-height: rem(16px);
      //   font-size: rem(16px);
      // }
    }
  }

  &2 {
    &::before {
      content: "\003E";
      color: #6BBBAE;
      padding-right: 5px;
    }
      line-height: rem(20px);
      font-size: rem(16px);
      letter-spacing: 0.12em;
      &--fw {
        font-weight: 700;
      }
  }
  &3 {
    border-radius: 30px;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.12em;
    font-weight: 700;
    padding: 18px 30px;
    }
  }

  .tag-list > .c-cta__tag:not(:nth-child(-n + 5)){
    display: none;
  }
  .tag-list.is-active > *.c-cta__tag {
    display: inline-block !important;
  }
