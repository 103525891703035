@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("nav2");

.#{$component} {
  & ul {
    list-style: none;
    & li {
      margin-bottom: 30px;
      & a:hover {
        color: tokenColor(set1, greenwater);
      }
    }
  }

  }

