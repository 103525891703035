@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("ratio");

[class*="#{$component}"] {
  &:before {
    display: block;
    content: " ";
    width: 100%;
    padding-top: calc(var(--h) / var(--w) * 100%);
  }

  @each $k, $v in tokenBreakpoints() {
    @if not($k == "xs") {
      @include media-breakpoint-up($k) {
        &[class*="--#{$k}-none"] {
          &:before {
            display: none;
          }
        }
      }
    } @else {
      &[class*="--none"] {
        &:before {
          display: none;
        }
      }
    }
  }
}

.#{$component} {
  &--square {
    &:before {
      --w: 1;
      --h: 1;
    }
  }
  &--wide {
    &:before {
      --w: 16;
      --h: 9;
    }
  }
  &--box {
    &:before {
      --w: 390;
      --h: 241;
    }
  }
  &--news {
    &:before {
      --w: 410;
      --h: 230;
    }

  @include media-breakpoint-down(lg) {
    &:before {
      --w: 280;
      --h: 158;
    }
  }
  }

  &--stickynews {
    &:before {
      --w: 1290;
      --h: 725;
    }
    @include media-breakpoint-down(lg) {
      &:before {
        --w: 380;
        --h: 506;
      }
  }
}

&--singlenews {
  &:before {
    --w: 1290;
    --h: 725;
  }
  @include media-breakpoint-down(lg) {
    &:before {
      --w: 380;
      --h: 215;
    }
}
}

&--contact {
  &:before {
      --w: 390;
      --h: 350;
    }
  }

  &--herobox {
    &:before {
      --w: 1290;
      --h: 540;
    }
  }

}
