@import "../../assets/scss/components/base";

$component: componentClass("modale");

.#{$component} {

  & .modal-dialog {
    max-width: 100% !important;
    margin: 0 !important;
  }

  &__btn--close {
    position: absolute;
    top:0;
    right: 0;
   & svg {
    font-size: 22px;
  }
}

  &__content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    &--search {
      @include media-breakpoint-down(md) {
        width: 100%
      }
    }
    & .form-group {
      border-bottom: 2px solid #3F3F3F;
      width: 610px;
      @include media-breakpoint-down(md) {
      width: 100%
      }

      & input {
        background-color: transparent;
        border: none;
        font-weight: 600;
        &:focus {
          outline: none;
        }
        &::placeholder {
          font-weight: normal;
      }
      &:placeholder-shown {
        background-image: url(../../assets/icon/icozaux1/SVG/search.svg);
        background-repeat: no-repeat;
        background-size: 23px 23px;
        background-position: bottom 10px right 20px;
      }
      }

    }
    &--search--result {
      & span {
        color: #979797
      }
    }

    &--search--suggestion {
      & span {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }

}

.btn.btn-primary {
  white-space: nowrap;
  // color: $zaux-color-set1-darkblue;
  text-decoration: none;
}
.modal-backdrop {
  z-index: 20;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
  appearance: none;
}
