@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("card1");

.#{$component} {

  &__content {
    min-height: 228px;
    @include media-breakpoint-down(lg) {
      min-height: 200px;
    }

    .#{$component}__content--cat {
      p {
        color: #3D3935;
        transition: color .5s ease;
      }
    }

    .#{$component}__content--info {
      p {
      color: #3D3935;
      transition: color .5s ease;
      }
    }
    .#{$component}__content--foot {
      a {
      color: #3D3935;
      transition: color .5s ease;
      }
    }

    &--foot {
      & span::before {
        content: "\003E";
        color: #6BBBAE;
        padding-right: 5px;
      }
    }
    &:hover {

      .#{$component}__content--cat {
        p {
          color: #6BBBAE;
          transition: color .5s ease;
        }
      }

      .#{$component}__content--info {
        p {
        color: #6BBBAE;
        transition: color .5s ease;
        }
      }
      .#{$component}__content--foot {
        a {
        color: #6BBBAE;
        transition: color .5s ease;
        }
      }

    }
  }

  &__contentnews {

    &.is-sticky {

      .#{$component}__contentnews--sticky {
        padding-left: 60px;
        padding-bottom: 72px;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 5;
        @include media-breakpoint-down(lg) {
        padding: 30px;
        }
      }
      .#{$component}__contentnews--title {
        h6 {
          color: white;
          transition: color .5s ease;
        }
      }

      .#{$component}__contentnews--info {
        p {
          color: white;
          transition: color .5s ease;
          }
      }

      .#{$component}__contentnews--foot {
        a, span {
       color: white;
       transition: color .5s ease;
        }
      }
        & > a::after {
            mix-blend-mode: multiply;
            background: linear-gradient(to bottom, #6BBBAE, #3D3935);
            opacity: 0;
            transition: opacity .5s ease;
            position: absolute;
            top: 0;
            left: 0;
            content: " ";
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 20px;
          }
        & > a:hover {
            &::after {
              opacity: 1;
              transition: opacity .5s ease;
          }
      }
    }

    &:not(.is-sticky) .#{$component}__contentnews--img {
      picture::after {
        mix-blend-mode: multiply;
        position: absolute;
        border-radius: 20px;
        top: 0;
        left: 0;
        content: " ";
        width: 100%;
        height: 100%;
        display: block;
        background: linear-gradient(to bottom, #6BBBAE, #3D3935);
        transition: opacity .5s ease;
        opacity: 0;
      }
    }


  &:hover {

    .#{$component}__contentnews--img {
      picture::after {
        opacity: 1;
        transition: opacity .5s ease;
      }
    }
    .#{$component}__contentnews--title {
      h6 {
        color: #6BBBAE;
        transition: color .5s ease;
      }
    }

    .#{$component}__contentnews--info {
      p {
      color: #6BBBAE;
      transition: color .5s ease;
      }
    }
    .#{$component}__contentnews--foot {
      a, span {
      color: #6BBBAE;
      transition: color .5s ease;
      }
    }

  }

    &--foot {
      & span {
        color: #3D3935;
       &::before {
        content: "\003E";
        color: #6BBBAE;
        padding-right: 5px;
      }
    }
    }
  }

  &__profile {
    max-height: 440px;

    &.active {
      max-height: unset;
      .#{$component}__profile--img {
        // visibility: hidden;
        opacity: 0;
        transition: opacity .2s;
        height: 20px;

      }
      .#{$component}__profile--bio {
      //  position: absolute;
      //  top: 0;
      //  left: 0;
      //  padding: 30px !important;
      transform: translateY(-20px);
        transition: transform .5s ease;
        &--txt {
          height: 100%;
          overflow: unset;
          max-height: unset;
          padding-bottom: 10px;
        }
        &--foot {
          & .cta-foot {
            display: none;
          }
          & .cta-foot-active {
            display: inline-block;
          }
        }
      }

    }

    &--img {
      opacity: 1;
      transition: opacity .2s;
    }

    &--bio--txt {
      max-height: 0;
      overflow: hidden;
      padding-bottom: 50px;
      margin-bottom: 15px;
    }

    &--bio--foot {
      position: absolute;
      bottom: 20px;
      & span:hover {
        color: #6BBBAE;
        cursor: pointer;
      }

      & span.cta-foot::before {
        content: "+";
        color: #6BBBAE;
        padding-right: 5px;
      }
      & span.cta-foot-active::before {
        content: "-";
        color: #6BBBAE;
        padding-right: 5px;
      }
      & .cta-foot {
        display: inline-block;
      }
      & .cta-foot-active {
        display: none;
      }
    }

  }

  &__timeline {

    &--link {
      position: relative;

      &--line {
        border-top-color:#6BBBAE;
        border-top: 2px solid #6BBBAE;
        position: absolute;
        top: 50%;
      }
      &.link-1 {
        .#{$component}__timeline--link--line {
          left: 50%;
          width: calc( 50% + 30px );
        }
      }
      &.link-2 {
        .#{$component}__timeline--link--line {
          left: 0;
          width: calc( 100% + 30px );
        }
      }
      &.link-3 {
        .#{$component}__timeline--link--line {
          right: 50%;
          width: calc( 50% + 30px );
        }
      }

      &--circle {
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #6BBBAE;
        margin: auto;
      }

    }
  }

  &__investitor {
    position: relative;
    & img {
      width: auto;
      height: 60px;
      // @include media-breakpoint-up(lg) {
      //   height: 40px;
      // }
    }

    &.is-dynamic {
    min-height: 166px;
    position: relative;
    & .c-card1__investitor--logo {
      opacity: 1;
      transition: opacity .3s ease;
      padding-bottom: 30px;
    }
    & .c-card1__investitor--txt {
      max-height: 0;
      overflow: hidden;
      padding-bottom: 50px;
      margin-bottom: 15px;
    }
    & .c-card1__investitor--foot {
      & a {
        font-size: 12px;
        letter-spacing: 1.92px;
        &::before {
          content: "\003E";
          color: #6BBBAE;
          padding-right: 5px;
        }
      }

      position: absolute;
      bottom: 0;
      width: 100%;
      & span:hover {
        color: #6BBBAE;
        cursor: pointer;
      }

      & span.cta-foot::before {
        content: "+";
        color: #6BBBAE;
        padding-right: 5px;
      }
      & span.cta-foot-active::before {
        content: "-";
        color: #6BBBAE;
        padding-right: 5px;
      }
      & .cta-foot {
        display: inline-block;
      }
      & .cta-foot-active {
        display: none;
      }

    }
    }
    &.is-dynamic.active {
      .#{$component}__investitor--logo {
        // visibility: hidden;
        opacity: 0;
        transition: opacity .2s;
        height: 0;
        padding-bottom: 20px;
      }

      & .c-card1__investitor--txt {
        // position: absolute;
        // top: 0;
        // left: 0;
       transition: transform .3s ease;
       height: 100%;
        max-height: unset;
        transform: translateY(-20px);
        padding-bottom: 0;
        margin-bottom: 0;
      }
      & .c-card1__investitor--foot {
        & .cta-foot {
          display: none;
        }
        & .cta-foot-active {
          display: inline-block;
        }
    }

    &--logo {
      a {
        span:hover {
          color: #6BBBAE;
          transition: color .5s ease;
        }
      }
    }

  }
}
}
