@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("menu");

.zaux-menu-active {
  overflow: hidden;
}

.#{$component} {
  overflow: auto;
  display: none;
  &.open {
    display: block;
  }

  position: fixed;
  top: 0;
  left: 0;
  z-index: 13;
  height: 100%;
  width: 100%;

  &__inner {
    overflow: auto;
    margin-top: 150px;
    }

  &__nav {
    min-height: calc( 100% - 150px );
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @include media-breakpoint-down(lg) {
    min-height: calc( 100% - 140px );
    }
    & ul {
      list-style: none;
    }
    &--list {
      min-height: calc( 100vh - 216px );
    }
    &--item {

      border-top: 1px solid #7C8795;
      padding-bottom: 60px;
      @include media-breakpoint-down(lg) {
        padding: 20px 0;
      }
      & > a, & div > a {
        color: white;
        font-weight: 800;
        font-size: 24px;
        line-height: 32px;
        @include media-breakpoint-up(lg) {
        font-size: 38px;
        font-weight: 700;
        line-height: 42px;
        margin-bottom: 30px;
        &:hover {
          color: tokenColor(set1,greenwater);
        }
        }
      }
      &--show {
        @include media-breakpoint-down(lg) {
          display: none;
        }
      }
      &-trigger {
        margin-left: 10px;
        transition: transform .3s cubic-bezier(.215,.61,.355,1);
        display: inline-block;
        font-size: 16px;
        @include media-breakpoint-up(lg) {
          display: none;
        }
      }
      &.open {
        & .#{$component}__nav--item-trigger {

          & i {
            transform-origin: center center;
            transform: translate(0px,-3px) rotateZ(-180deg);
          }

        }

        & .#{$component}__nav--item--show {
          display: block;
        }

      }

      // &-trigger::after {
      //   @include media-breakpoint-down(lg) {
      //   content: '\2BC6';
      //   font-size: 16px;
      //   color: white;
      //   }
      // }
    }

  }

  &__foot {
    height: 66px;
    border-top: 1px solid #7C8795;
    &--info {
      @include media-breakpoint-down(lg) {
        display: none;
      }
      & ul {
        list-style: none;
        & li {
          display: inline-block;
          & a {
            font-weight: 800;
          }
        }
      }
    }
    &--social {
      display: flex;
    }

  }


  }

