@charset "UTF-8";

.zaux-svg-fluid-wrapper {
  display: block;
  line-height: 0;
  svg {
    max-width: 100%;
  }
}

:root:not(.ie-legacy) {
  .zaux-svg-fluid-wrapper {
    svg {
      height: auto;
    }
  }
}

// .zaux-zi--1 {
//   z-index: 1;
// }
.zaux-zi-1 {
  z-index: 1;
}
.zaux-zi-2 {
  z-index: 2;
}
.zaux-zi-5 {
  z-index: 5;
}
.zaux-zi-4 {
  z-index: 4;
}


.background-wave-hero {
  background-image: url('../../assets/img/BackgroundWave-2560.png');
  background-repeat: no-repeat;
  background-position: center center;
  @include media-breakpoint-up(lg) {
    background-size: contain;
  }
  @include media-breakpoint-down(lg) {
    background-image: url('../../assets/img/BackgroundWave-992.png');
  }
}
.background-wave-v-hero {
  background-image: url('../../assets/img/BackgroundWave-2560-v.png');
  background-repeat: no-repeat;
  background-position: center center;
  @include media-breakpoint-up(xl) {
    background-size: contain;
  }
  @include media-breakpoint-down(lg) {
    background-image: url('../../assets/img/BackgroundWave-992-v.png');
  }
}


.background-wave {
  background-image: url('../../assets/img/BackgroundWave-2560.png');
  background-repeat: no-repeat;
  background-position: center center;
  @include media-breakpoint-up(xl) {
    background-size: contain;
  }
  @include media-breakpoint-down(lg) {
    background-image: url('../../assets/img/BackgroundWave-992.png');
  }
}
.background-wave-h {
  background-image: url('../../assets/img/BackgroundWave-2560-h.png');
  background-repeat: no-repeat;
  background-position: center center;
  @include media-breakpoint-up(xl) {
    background-size: contain;
  }
  @include media-breakpoint-down(lg) {
    background-image: url('../../assets/img/BackgroundWave-992-h.png');
  }
}
.background-wave-v {
  background-image: url('../../assets/img/BackgroundWave-2560-v.png');
  background-repeat: no-repeat;
  background-position: center center;
  @include media-breakpoint-up(xl) {
    background-size: contain;
  }
  @include media-breakpoint-down(lg) {
    background-image: url('../../assets/img/BackgroundWave-992-v.png');
  }
}



.zaux-fit-cover-center-left {
  object-fit: cover;
  object-position: left center;
}
.zaux-fit-cover-custom1 {
  object-fit: cover;
  object-fit: center center;
  @include media-breakpoint-down(lg){
    object-position: bottom;
  }
}


.zaux-col-80 {
  @include media-breakpoint-down(md){
    width: 80%;
  }
}
.zaux-loghi-partner {
  position: relative;
  &--box {
  padding-bottom: 30px;
  padding-right: 40px;
  @include media-breakpoint-up(lg){
  padding-right: 90px;
  padding-bottom: 60px;
  }
  & img {
    height: 40px;
    width: auto;
    @include media-breakpoint-up(lg){
      height: 60px;
    }
  }
}
&.logs-shadow {
  &--box {
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    @include media-breakpoint-up(lg){
      padding-left: 45px;
      padding-right: 45px;
      padding-bottom: 60px;
    }
  }
@include media-breakpoint-down(lg){
&::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, #DCEDED00 0%, #DCEDED 100%);
    height: 220px;
    width: 100%;
}
}
}
&.logs-shadow-wh {
  &--box {
    padding-bottom: 40px;
    padding-left: 30px;
    padding-right: 30px;
    @include media-breakpoint-up(lg){
      padding-left: 45px;
      padding-right: 45px;
      padding-bottom: 60px;
    }
  }
@include media-breakpoint-down(lg){
&::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: linear-gradient(to bottom, #FFFFFF00 0%, #FFFFFF 100%);
    height: 220px;
    width: 100%;
}
}
}
}

.zaux-list-team {
  margin: 0 -15px;
  @include media-breakpoint-down(lg){
    overflow: overlay;
    padding: 30px 0;
    }
}

.zaux-list-team-card {
  margin: 0 15px;
  @include media-breakpoint-down(lg){
  margin: 0 8px;
  }
}

.c-newsscroll--tab {
  height: 98px;
  position: relative;
  z-index: 10;
}

.c-navscroll {
  @include media-breakpoint-up(lg){
    position: sticky;
    top: 90px;
    }
}
.c-newsscroll--tab {
  position: sticky;
  top:40px;
  @include media-breakpoint-up(lg){
    top: 60px;
    }
}


