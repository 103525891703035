@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("hero");


.#{$component} {

  &__wrapper {
    height: 630px;
    @include media-breakpoint-down(lg) {
      height: 620px;
    }
  }

}
