@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("typo");

////////////////////////////////////////////////////
// Theme: Light1
////////////////////////////////////////////////////

// .zaux-theme--light1 {
//   // h1,
//   // .h1,
//   // h2,
//   // .h2,
//   // h3,
//   // .h3,
//   // h4,
//   // .h4,
//   // h5,
//   // .h5,
//   // h6,
//   // .h6,
//   // p,
//   // li {
//   // }

//   a {
//     @at-root .#{$zaux-system-classname-loaded} & {
//       transition: color $zaux-transition-1;
//     }
//   }
// }

.zaux-theme--light1 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6,
  p,
  li {
    color: tokenColor(set1, darkgrey);
  }

  a {
    color: tokenColor(set1, darkgrey);
    @at-root .#{$zaux-system-classname-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: tokenColor(set1, darkgrey);
    }

    &:hover,
    &:active {
      color: tokenColor(set1, greenwater);
      // text-decoration: underline;
      text-decoration: none;
    }
  }
}

/////////
///
.zaux-theme--light2 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6{
    color: tokenColor(set1, greenwater);
  }

  p,
  li {
    color: tokenColor(set1, greenwater);
  }

  a {
    @at-root .#{$zaux-system-classname-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: tokenColor(set1, greenwater);
    }

    &:hover,
    &:active {
      color: tokenColor(set1, greenwater);
      // text-decoration: underline;
      text-decoration: none;
    }
  }
}

.zaux-theme--light3 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: tokenColor(set1, darkgrey);
  }

  p,
  li {
    color: tokenColor(set1, darkgrey);
  }
  li {
    padding-left: 10px;
    margin-bottom: 20px;
    &::marker {
      content: "\003E";
      color: #6BBBAE;
      font-weight: 700;
    }
  }

  a {
    color: tokenColor(set1, greenwater);
    text-decoration: underline;
    @at-root .#{$zaux-system-classname-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: tokenColor(set1, greenwater);
    }

    &:hover,
    &:active {
      color: tokenColor(set1, greenwater);
      // text-decoration: underline;
      text-decoration: none;
    }
  }
}

.zaux-theme--light4 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3 {
    color: tokenColor(set1, darkgrey);
    font-size: 2.5rem;
  }

  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: tokenColor(set1, darkgrey);
    font-size: 1.5rem;
  }

  p,
  li {
    color: tokenColor(set1, darkgrey);
  }
  li {
    padding-left: 10px;
    margin-bottom: 20px;
    &::marker {
      content: "\003E";
      color: #6BBBAE;
      font-weight: 700;
    }
  }

  a {
    color: tokenColor(set1, greenwater);
    text-decoration: underline;
    @at-root .#{$zaux-system-classname-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: tokenColor(set1, greenwater);
    }

    &:hover,
    &:active {
      color: tokenColor(set1, greenwater);
      // text-decoration: underline;
      text-decoration: none;
    }
  }
}

////////////////////////////////////////////////////
// Theme: Dark1
////////////////////////////////////////////////////

.zaux-theme--dark1 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: tokenColor(default, white);
  }

  p,
  li {
    color:tokenColor(default, white);
  }

  a {
    color: tokenColor(set1, greenwater );
    @at-root .#{$zaux-system-classname-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: tokenColor(set1, greenwater);
    }

    &:hover,
    &:active {
      color: tokenColor(set1, greenwater);
    }
  }
}

.zaux-theme--dark2 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: tokenColor(default, white);
  }

  p,
  li {
    color:tokenColor(default, white);
  }

  a {
    color: white;
    @at-root .#{$zaux-system-classname-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: white;
    }

    &:hover,
    &:active {
      color: tokenColor(set1, greenwater);
    }
  }
}

.zaux-theme--dark3 {
  h1,
  .h1,
  h2,
  .h2,
  h3,
  .h3,
  h4,
  .h4,
  h5,
  .h5,
  h6,
  .h6 {
    color: tokenColor(default, white);
  }

  p,
  li {
    color:tokenColor(default, white);
  }
  li {
    padding-left: 10px;
    &::marker {
      content: "\003E";
      color: #6BBBAE;
      font-weight: 700;
    }
  }

  a {
    color: white;
    @at-root .#{$zaux-system-classname-loaded} & {
      transition: color $zaux-transition-1;
    }

    &:link,
    &:visited {
      color: white;
    }

    &:hover,
    &:active {
      color: tokenColor(set1, greenwater);
    }
  }
}
