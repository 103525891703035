@charset "UTF-8";

@import "../../assets/scss/components/base";

$component: componentClass("wrapper");

.#{$component} {

  &--w1 {
      max-width: 1330px;
      padding-left: 20px;
      padding-right: 20px;
  }

  &--w2 {
    max-width: 1380px;
  }

  &--w3 {
    max-width: 1410px;
  }

  &--sectionhead {
    letter-spacing: 12.8px;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 400;
  }
}

[class*="#{$component}--"] {
  margin-right: auto;
  margin-left: auto;
}

