@charset "UTF-8";

@import "../abstracts/variables";
@import "../abstracts/functions";
@import "../abstracts/mixins";
@import "../abstracts/placeholders";

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/utilities";

$zaux-helper-name: "pos";

// Custom

.zaux-#{$zaux-helper-name}-svg1 {
  position: absolute;
  left: 0;
  top:-90px;
  z-index: 0;
}

.zaux-#{$zaux-helper-name}-tabnews {
  @include media-breakpoint-up(lg) {
  position: fixed;
  top: 60px;
  z-index: 6;
  width: 100%;
  max-width: 410px;
  }
}
// .zaux-#{$zaux-helper-name}-custom2 {
//   top:30px;
//   @include media-breakpoint-down(lg) {
//     top:15px;
//     left: -300px;
//   }
// }

// .zaux-#{$zaux-helper-name}-custom3 {
//   @include media-breakpoint-up(md) {
//    position: absolute;
//    left: 0;
//    }
//  }

//  .zaux-#{$zaux-helper-name}-custom4 {
//   @include media-breakpoint-down(lg) {
//     transform: translateY(45px) rotate(180deg);
//    }
//  }

//Custom Hero Text Box
  .zaux-#{$zaux-helper-name}-herobox {
    position: absolute;
    bottom: 120px;
    @include media-breakpoint-down(lg) {
      bottom: 60px;
     }
   }


// // Custom Twitter Box
// .zaux-#{$zaux-helper-name}-twitter1 {
//     position: absolute;
//     left: 188px;

// }
// .zaux-#{$zaux-helper-name}-twitter2 {
//     position: absolute;
//     right: 188px;

// }


// //Custom Benefits Story Card
// .zaux-#{$zaux-helper-name}-story1 {
//  @include media-breakpoint-up(lg) {
//   position: absolute;
//   top: 100px;
//   }
// }

// //Custom Benefits cta Seminar Card
// .zaux-#{$zaux-helper-name}-ctasem {
//    position: absolute;
//    bottom: 30px;
//    right: 0;
//    left: 0;
//  }

// //Custom Header position on scroll
// .zaux-#{$zaux-helper-name}-header1 {
//   @include media-breakpoint-up(lg) {
//    position: absolute;
//    left: 350px;
//    }
//  }
//  .zaux-#{$zaux-helper-name}-header2 {
//   @include media-breakpoint-up(lg) {
//    position: absolute;
//    }
//  }
//  .zaux-#{$zaux-helper-name}-header3 {
//   @include media-breakpoint-up(lg) {
//    position: absolute;
//    right: 93px;
//    }
//  }


 //Custom Nav News sticky on scroll
 .zaux-#{$zaux-helper-name}-nav {
    position: fixed;
    max-width: 1290px;
    width: 100%;
    z-index: 11;
    top: 60px;
 }

